<template>
	<ProductInfo
		:product="product"
		:id="id"
		v-if="Number.isInteger(product.status)"
		class="pa-5"
	/>
</template>


<script>
	import ProductInfo from '@/components/modules/@product/ProductInfo';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			ProductInfo,
		},
		data() {
			return {
				product: this.$models.product,
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userInfo = doc.data();

				this.$db.collection("companies").doc(this.userInfo.company).collection("products").doc(this.id).onSnapshot(doc => {
					this.product = doc.data();
					this.product.type = "default";

					document.title = `Produit ${this.product.NumPolice || ''} • ${this.product.name || this.product.MarqueSecond}`;
				});
			});
		}
	}

</script>
