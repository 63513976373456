<template>
	<div>
		<v-col class="mb-10">
			<v-card-actions>
				<v-btn right color="primary" outlined v-if="editor == false" :to="{path: '../../products'}">
					<v-icon left>
						mdi-arrow-left
					</v-icon>
					Retour
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn right color="secondary" outlined @click="editor = ! editor" v-if="editor == false">
					<v-icon left>
						mdi-pencil
					</v-icon>
					Editer
				</v-btn>

				<v-btn right color="warning" outlined @click="editor = ! editor" v-if="editor == true">
					<v-icon left>
						mdi-cancel
					</v-icon>
					Annuler
				</v-btn>


				<v-btn right color="success" outlined @click="submit" v-if="editor == true">

					<v-icon left>
						mdi-pencil
					</v-icon>
					Valider
				</v-btn>


			</v-card-actions>
		</v-col>

		<v-row align-content="start" class="mb-5">
			<v-col cols="12" sm="5" v-if="editor == true">
				<v-row align-content="start">
					<v-col cols="3">
						<v-list-item v-for="picture in product.pictures" :key="picture.key">
							<v-list-item-avatar tile width="250px" height="50px">
								<v-img contain :src="$functions.getImgLink(picture.link, 200)"></v-img>
							</v-list-item-avatar>
						</v-list-item>
					</v-col>
					<v-col cols="8">
						<div v-for="picture in product.pictures" :key="picture.key">
							<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="product" :label="`Image n°${picture.key + 1}`" @newdata="product.pictures[picture.key].link=$event"></ImageUpload>
						</div>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" sm="5" v-if="editor == false">

				<v-row align-content="start">
					<v-col cols="1">
						<v-list-item v-for="picture in product.pictures" :key="picture.key">
							<v-list-item-avatar tile>
								<v-btn @click="activeThumbnail = picture.link">
									<v-img max-width="50px" contain :src="$functions.getImgLink(picture.link, 50)"></v-img>
								</v-btn>
							</v-list-item-avatar>
						</v-list-item>
					</v-col>

					<v-col cols="11">
						<v-img :src="$functions.getImgLink(activeThumbnail || this.product.pictures[0].link, 200)" lazy-src="activeThumbnail" min-height="100" max-height="250" contain>
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="7" class="px-5 py-0">
				<v-card>
					<v-card-text>
						<v-row v-if="editor == false" class="pa-3">
							<h1>{{ product.name }}</h1>
							<v-spacer></v-spacer>
							<v-chip :color="$cssGlobal.getStatus($listUtils.productStatus[product.status], 'product')" outlined fab dark height="15px" width="15px" center>{{ $listUtils.productStatus[product.status] }}</v-chip>
						</v-row>

						<p class="font-weight-light" v-if="editor == false">Référence : {{ product.reference }}</p>

						<h2 v-if="editor == false">{{ Number(product.value).toLocaleString(undefined, { minimumFractionDigits: 2}) }}€</h2>

						<v-row v-if="editor == true">
							<v-col cols="8">
								<v-text-field label="Nom" hint="Nom du produit" v-model="product.name"></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-text-field label="Référence" hint="Référence du produit" v-model="product.reference"></v-text-field>
							</v-col>
						</v-row>

						<v-row v-if="editor == true">
							<v-col cols="2">
								<v-text-field label="Prix" hint="Prix du produit" v-model="product.value" suffix="€" type="number"></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-select v-model="status" :items="this.$listUtils.productStatus" label="Statut du produit" required></v-select>
							</v-col>
						</v-row>

						<div v-html="product.shortDesc" v-if="editor == false"></div>
						<vue-editor v-model="product.shortDesc" v-if="editor == true" />

						<div v-if="product.Kilometrage && editor == false">
							<h4>{{ this.product.Marque }} • {{ product.Version }}</h4>
							<p>{{ product.Millesime }} | {{ product.Kilometrage.toLocaleString(undefined) }} km | {{ product.Energie }}</p>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-card class="mx-2">
			<v-card-title>
				Description Produit
			</v-card-title>
			<v-card-text>
				<div v-html="product.longDesc" v-if="editor == false"></div>

				<vue-editor v-model="product.longDesc" v-if="editor == true" />
			</v-card-text>
		</v-card>
	</div>
</template>


<script>
	import {
		VueEditor
	} from "vue2-editor";
	import ImageUpload from '@/components/graphics/ImageUpload';


	export default {
		props: {
			'id': {
				type: String
			},
			'product': {
				type: Object
			}
		},
		components: {
			VueEditor,
			ImageUpload
		},
		data() {
			return {
				userInfo: this.$models.user,

				editor: false,
				activeThumbnail: "",

				activeImage: '',

				status: ''
			}
		},
		watch: {
			product: function() {
				this.activeThumbnail = this.product.pictures[0].link;
			}
		},
		methods: {
			submit() {
				var object = {
					...this.product,
					'status': this.$listUtils.productStatus.indexOf(this.status),
					'value': Number(this.product.value)
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("products").doc(this.id).set(object, {
					merge: true
				});

				this.$store.dispatch('setSnackbar', {
					text: `Le produit ${object.name} a été modifié !`,
				});

				this.editor = !this.editor;
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();
				console.log(this.$listUtils.productStatus[this.product.status], this.product.status)

				this.status = this.$listUtils.productStatus[this.product.status];
			});
		}
	}
</script>
