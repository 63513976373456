<template>

	<v-file-input 
		:loading="loading"
		@change="preUploadImage"
		accept="image/*"
		:label="label"
		@click:clear="unFocus"
		id="file-input"
	>
		<template slot="progress">
			<v-progress-linear :indeterminate="progressBar.indeterminate" :value=" progressBar.value" :color="progressBar.color"></v-progress-linear>
		</template>
	</v-file-input>

</template>

<script>
	import {
		v4 as uuidv4
	} from 'uuid';

	export default {
		name: "ImageUpload",
		props: {
			companyID: {
				type: String
			},
			type: {
				type: String
			},
			label: {
				type: String,
				default: "Image"
			}
		},
		data() {
			return {
				downloadURL: '',

				progressBar: {
					indeterminate: true,
					value: 0,
					color: 'grey lighten-1'
				},

				loading: false,

				mainRef: '',

				storage: this.$firebase.app().storage("gs://katana-main")
			}
		},
		methods: {
			unFocus() {
				this.$emit('newdata', "");
				document.getElementById('file-input').blur();

				//this.storage.ref(this.mainRef).delete();
			},
			preUploadImage(e){
				if(!e) return;

				if(['image/jpeg', 'image/png', 'image/tiff', 'image/webp'].indexOf(e.type) === -1) return this.$store.dispatch('setSnackbar', {
					text: "Le format de l'image est incorrect.",
					color: "error"
				});

				this.loading = true;

				this.uploadImage(e);
			},
			uploadImage(file){
				if(!file) return;

				//const fileSize = Math.round(file.size / 100);

				if(!file.type.includes("image")) {
					this.$store.dispatch('setSnackbar', {
						text: "Seules les images sont autorisées",
						color: "error"
					});

					return;
				}
				
				var uploadTask = this.storage.ref(this.mainRef).put(file);

				uploadTask.on('state_changed',
					(snapshot) => {
						this.progressBar.indeterminate = false;
						var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

						this.progressBar.value = progress;

						if (progress > 80) {
							this.progressBar.color = "success lighten-2";
						}
					},
					(error) => {
						this.$store.dispatch('setSnackbar', {
							text: "Erreur : " + error,
							color: "error"
						});
					},
					() => {
						uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
							this.progressBar.indeterminate = true;

							setTimeout(() => {
								this.$emit('newdata', downloadURL);

								this.progressBar = {
									indeterminate: true,
									value: 0,
									color: 'grey lighten-1'
								};

								this.loading = false;
							}, 4000);
						});
					}
				);
			}
		},
		created() {
			if(this.type === "self") this.mainRef = `users/${this.companyID}/src/${uuidv4()}`;
			else this.mainRef = `companies/${this.companyID}/${this.type}_src/${uuidv4()}`;
		}
	}

</script>
